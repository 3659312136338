const point = "https://hmpb.rekopgames.com"

/* SOCKET TOKEN */
const DEALER_MIDDLEWARE_TOKEN = '07ecbab1-6b10-4806-ab9c-687778167086'

/* POINTS */
const startpoint = `${point}/api/`
const mediapoint = "https://cb.rekopgames.com"


export { point, mediapoint, startpoint, DEALER_MIDDLEWARE_TOKEN }
