import React, { Component } from "react"

/* Components */
import { Logo, Dealer, Timing, Pause, PauseModal, Messages } from './components'

/* Constants */
import { point, mediapoint, DEALER_MIDDLEWARE_TOKEN } from './constants/env'

/* Socket IO */
import { io } from "socket.io-client"

/* Pages */
import { Pin } from './pages'

/* SweetAlert */
import Swal from 'sweetalert2'

/* Sound */
import { sound } from './constants'


/* Fields */
const PLAYER_NUMBERS = [1, 3]
const ADDITIONAL_NUMBERS = [5, 6, 7, 8, 9]
const DEALER_NUMBERS = [2, 4]

const COMMANDS = [
    {
        key: "waiting",
        gradient: 'mignight'
    },
    {
        key: "deal",
        gradient: 'lush'
    },
    {
        key: "dealt",
        gradient: 'lush'
    },
    {
        key: "additional",
        gradient: 'lush'
    },
    {
        key: "dealer",
        gradient: 'royal'
    },
    {
        key: "end",
        gradient: 'mignight'
    }
]


/* Entry Point */
class App extends Component {

    constructor() {
        super()

        /* TOKEN */
        const dealer = localStorage.getItem("dealer")

        this.state = {
            dealer: JSON.parse(dealer),

            /* Game */
            showCard: true,
            command: "waiting",
            isPaused: false,
            isRevoke: false,
            messages: [],

            openDealer: false,
            active: 1,

            /* Dealer */
            dealerCards: [],
            dealerGame: null,

            /* Player */
            playerCards: [],
            playerGame: null,

            /* Additional */
            additionalCards: [],

        }

        /* Start SOCKET connection */
        this.socket = io(point, { auth: { token: `${DEALER_MIDDLEWARE_TOKEN}` } })
    }


    componentDidMount = () => {
        this.events()
    }

    componentWillUnmount = () => {
        this.removeEvents()
    }


    /* Socket events */
    events = () => {


        this.socket.on("connect", () => {
            this.socket.emit("monitorReconnection")
        })

        this.socket.on("monitorReconnection", data => {
            this.setState({
                dealerCards: data.dealerCards,
                dealerGame: data.dealerGame,
                playerCards: data.playerCards,
                playerGame: data.playerGame,
                additionalCards: data.additionalCards,
                openDealer: data.openDealer,
                isPaused: data.isPaused
            })
        })

        this.socket.on("disconnect", () => {
            this.logout()
        })

        /*
            AUTHORIZATION
            EVENTS
        */

        /* ON DEALER CONNECT */
        this.socket.on("dealerConnect", response => {
            this.authorization(response)
        })



        /*
            GAME STATUS AND TIMING
            EVENTS
        */
        this.socket.on("monitorCommand", data => {
            if (data) {

                console.log(data)

                this.setState({ command: data.command, showCard: data.showCard })

                if (data.command === "dealer") {
                    this.setState({ openDealer: true })
                }

                if (data.command === 'end') {
                    this.clear()
                }

                if (this._timing && data.command === "noplayer") {
                    this._timing.start(0, data.command)
                }

                if (this._timing && data.timer && parseInt(data.timer) > 0 && data.nextCommand) {
                    this._timing.start(data.timer, data.nextCommand)
                }

            }
        })



        /*
            CARD AND GAME 
            EVENTS
        */

        /* DEALER CARD */
        this.socket.on("monitorDealerCards", card => {
            const { dealerCards, active } = this.state
            let cards = dealerCards
            cards.push(card)
            const tempActive = active + 1
            this.setState({ dealerCards: cards, active: tempActive === 8 ? 7 : tempActive })
        })


        /* DEALER GAME */
        this.socket.on("monitorDealerGame", game => {
            this.setState({ dealerGame: game })
        })


        /* PLAYER CARD */
        this.socket.on("monitorPlayerCards", card => {
            const { playerCards, active } = this.state
            let cards = playerCards
            cards.push(card)
            this.setState({ playerCards: cards, active: active + 1 })
        })

        /* PLAYER GAME */
        this.socket.on("monitorPlayerGame", game => {
            this.setState({ playerGame: game })
        })

        /* ADDITIONAL CARD */
        this.socket.on("monitorAdditionalCards", card => {
            const { additionalCards, active } = this.state
            let cards = additionalCards
            cards.push(card)
            const tempActive = active + 1
            this.setState({ additionalCards: cards, active: tempActive === 8 ? 7 : tempActive })
        })

        /* ACTIVE SPACE */
        this.socket.on("activeSpace", data => {
            const { active } = data
            this.setState({ active })
        })



        /* 
            MESSAGE AND PAUSE STATUS
            EVENTS
        */

        /* MESSAGE */
        this.socket.on("message", data => {

            const { messages } = this.state

            if (messages.length === 50) {
                messages.pop()
            }

            messages.unshift(data)
            this.setState({ messages })

            sound.audio('message', 0.5)

        })

        /* MESSAGE */
        this.socket.on("monitorMessage", data => {

            const { messages } = this.state

            if (messages.length === 50) {
                messages.pop()
            }

            messages.unshift(data)
            this.setState({ messages })

            sound.audio('message', 0.5)
        })


        /* 
            ACTIONS FROM ADMIN 
            EVENTS
        */

        /* PAUSE EVENT FROM ADMIN */
        this.socket.on("pause", () => this.setState({ isPaused: true }))

        /* CONTINUE EVENT FROM ADMIN */
        this.socket.on("monitorContinue", data => {
            this.setState({ isPaused: false })
        })

        /* CONTINUE EVENT FROM ADMIN */
        this.socket.on("monitorRevoke", () => {

            this.setState({ isPaused: false, isRevoke: true, active: 1 })

            const timer = setTimeout(() => {
                this.setState({ isRevoke: false })
                clearTimeout(timer)
            }, 2000)

        })

        /* FORCE END EVENT FROM ADMIN */
        this.socket.on("monitorForceEnd", () => this.clear())
    }


    /* Remove socket events */
    removeEvents = () => {
        this.socket.disconnect()
        this.logout()
    }


    /* CLEAR ACTION */
    clear = () => {
        this.setState({

            /* Game */
            showCard: false,
            command: "waiting",
            isPaused: false,
            openDealer: false,
            active: 1,

            /* Dealer */
            dealerCards: [],
            dealerGame: null,

            /* Player */
            playerCards: [],
            playerGame: [],

            /* Additional */
            additionalCards: [],

        })
    }


    /* AUTHORIZATION */
    authorization = response => {

        if (response.message === "success") {
            this.setState({ dealer: response.data })
            localStorage.setItem("dealer", JSON.stringify(response.data))
        }

        if (response.message === "unauthenticated") {
            Swal.fire({ html: '<p class="pincode-error">Неверный PIN код!</p>', backdrop: false, position: 'top-right', showConfirmButton: false, timer: 1500, background: 'white' })
        }

        if (response.message === "error") {
            Swal.fire({ html: '<p class="pincode-error">Ошибка! Что-то пошло не так ...</p>', backdrop: false, position: 'top-right', showConfirmButton: false, timer: 1500, background: 'white' })
        }

        if (this._pin) {
            this._pin.toggleLoader(false)
            this._pin.clearValues()
        }

    }


    /* AUTHENTICATION */
    authenticate = pincode => {

        if (this._pin) {
            this._pin.toggleLoader(true)
        }

        this.socket.emit("dealerConnect", pincode)
    }

    /* LOGOUT ACTION */
    logout = () => {
        this.setState({ dealer: null })
        localStorage.removeItem("dealer")
        this.socket.emit("dealerData", null)
        this.clear()
    }

    /* PAUSE ACTION */
    pause = () => {
        this.setState({ isPaused: true })
        this.socket.emit("pause", "dealer")
    }

    /* Draw result */
    _result = type => {

        const { playerGame, dealerGame } = this.state

        let game = null

        if (type === "player") {
            game = playerGame
        }

        if (type === "dealer") {
            game = dealerGame
        }

        if (game !== null) {

            if (parseInt(game.level) === 0) {
                return <div className="game-result game-result-red">Нет игры</div>
            }

            if (parseInt(game.level) > 0) {

                let result = game.name

                if (game.hasAceKing) {
                    result = result + " + Туз Король"
                }

                return <div className="game-result">{result}</div>
            }

        }

    }


    /* Draw Player Cards */
    _player = () => {

        const { active, playerCards } = this.state

        return (
            <div className="player-cards">

                <div className="card-title">PLAYER</div>

                {this._result("player")}

                <div className="cards playerCards">

                    {/* Draw card spaces */}
                    <div className="spaces playerSpace">
                        {PLAYER_NUMBERS.map((item, index) =>
                            <div key={`${index}`} className="space">
                                {item === active && <div className="space-active" />}
                                <div className="space-title">{item}</div>
                                <div className="space-text">p{index + 1}</div>
                            </div>
                        )}
                    </div>

                    {/* Draw cards */}
                    {playerCards.map((item, index) =>
                        <div className="card animation" key={`${index}`}>
                            <img src={`${mediapoint}/cards/${item.image}`} alt="CARD" />
                        </div>
                    )}

                </div>

            </div>
        )

    }

    /* Draw Additional Cards */
    _additional = () => {

        const { active, additionalCards } = this.state

        return (
            <div className="additional-cards" style={{ opacity: active > 4 ? 1 : 0.4 }}>

                <div className="card-title">ADDITIONAL</div>

                <div className="cards">

                    {/* Draw card spaces */}
                    <div className="spaces">
                        {ADDITIONAL_NUMBERS.map((item, index) =>
                            <div key={`${index}`} className="space">
                                {item === active && <div className="space-active" />}
                                <div className="space-title">{item}</div>
                                <div className="space-text">ex{index + 1}</div>
                            </div>
                        )}
                    </div>

                    {/* Draw cards */}
                    {additionalCards.map((item, index) =>
                        <div className="card animation" key={`${index}`}>
                            {this._flip(item, true)}
                        </div>
                    )}

                </div>

            </div>
        )
    }

    /* Draw Dealer Cards */
    _dealer = () => {

        const { active, dealerCards, openDealer } = this.state

        return (
            <div className="dealer-cards">

                <div className="card-title">DEALER</div>

                {this._result("dealer")}

                <div className="cards dealerCards">

                    {/* Draw card spaces */}
                    <div className="spaces dealerSpace">
                        {DEALER_NUMBERS.map((item, index) =>
                            <div key={`${index}`} className="space">
                                {item === active && <div className="space-active" />}
                                <div className="space-title">{item}</div>
                                <div className="space-text">d{index + 1}</div>
                            </div>
                        )}
                    </div>

                    {/* Draw cards */}
                    {dealerCards.map((item, index) =>
                        <div className="card animation" key={`${index}`}>
                            {this._flip(item, (openDealer || index === 4))}
                        </div>
                    )}

                </div>

            </div>
        )

    }

    /* Draw card image */
    _image = (card, open) => {

        if (open) {
            return <img src={`${mediapoint}/cards/${card.image}`} alt="Dealer Card" />
        }

        return <img src={`${mediapoint}/cards/closed.webp`} alt="Dealer Card" />
    }


    /* Draw flip animation box */
    _flip = (card, open) => (
        <div className={`flip-card ${open ? 'active' : ''}`}>
            <div className="flip-card-inner">
                <div className="flip-card-front">
                    <img src={`${mediapoint}/cards/closed.webp`} alt="Card" />
                </div>
                <div className="flip-card-back">
                    {this._image(card, open)}
                </div>
            </div>
        </div>
    )



    /* Draw Cards */
    _cards = () => {

        const { showCard } = this.state

        if (showCard) {
            return (
                <div className="game-cards">
                    {this._player()}
                    {this._additional()}
                    {this._dealer()}
                </div>
            )
        }

    }


    /* Determine Background Color */
    background = () => {

        const { command } = this.state

        const index = COMMANDS.findIndex(c => c.key === command)

        if (index > - 1) {
            return COMMANDS[index]
        }

        return COMMANDS[0]
    }


    render = () => {

        /* Fields */
        const { dealer, command, isPaused, messages } = this.state

        /* PIN CODE */
        if (!dealer) {
            return <Pin ref={ref => this._pin = ref} authenticate={pincode => this.authenticate(pincode)} />
        }

        const background = this.background()

        return (
            <div className={`game ${background.gradient}`}>

                <Logo />

                <div className="top-right-box">
                    <Pause command={command} pause={() => this.pause()} />
                    <Dealer dealer={dealer} logout={() => this.logout()} />
                </div>

                <Timing ref={ref => this._timing = ref} isPaused={isPaused} />

                {this._cards()}

                <PauseModal isPaused={isPaused} />
                <Messages messages={messages} />

            </div>
        )
    }

}

export default App